import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#F7F6F4',
      neutralLight2: '#E2DFDA',
      neutralLight1: '#EDE9E2',
      neutralDark1: '#6B767E',
      primaryDark1: '#000000',
      primaryDark2: '#082D45',
      primaryDark3: '#0A334E',
      primaryDark4: '#2E5671',
      primaryLight1: '#CEAE43',
      primaryLight2: '#C6A022',
      danger: '#DE7865',
    },
  },
  fontFamily: {
    heading: "'DM Serif Display', serif",
    paragraph: "'Inter', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
